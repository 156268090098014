import React, { useState, Fragment } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, Button } from '@radix-ui/themes';
import './App.css';

import { Splash } from './Splash.js';
import { Home } from './Home.js';
import { MkRefresh, randn } from './Lib.js';


function X() {
  return (
  	<>
	</>
  );
}


function Sidebar() {
  return (
  <Flex direction="column" justify="between" height="100vh" >
  	<Box>
		top
	</Box>
  	<Box>
		bot
	</Box>
  </Flex>
  );
}


function Topbar() {
  return (
  <Flex>
  	<Box>
		Topbar
	</Box>
  </Flex>
  );
}


function Mainbar() {
  return (
  <Box flexGrow="1">
  <Flex direction="column" justify="between" height="100vh" 
  		style={{ background: '#eef' }}
  >
  	<Box>
		Mainbar
	</Box>
  </Flex>
  </Box>
  );
}

function Layout() {
  return (
    <Box className="App" >
    	<Flex direction="row">
		<Sidebar />
		<Mainbar />
	</Flex>
    </Box>
  );
}


function getRandomIntInRange(min, max) {
  min = Math.ceil(min); // Ensure minimum is an integer
  max = Math.floor(max); // Ensure maximum is an integer
  return Math.floor(Math.random() * (max - min + 1)) + min; 
}

const gallery_start = randn(10000);
window.my_default = {
	signup: {name: '', email: '', code: ''},
	handlers: {},
	circles: {},
	store: {},
	poll: {id : null, count: 0, stop: false},
	astro: {},
	current: { gallery : {
		search : { start: 0, more : false },
		browse: { start: gallery_start, more: false, 
			inited: false, shuffle: [gallery_start] }},
		settings: { isFullScreen : false,
			showFavCircles: false,
			showFavNotebooks: false,
		},
		csrfToken: '',
	}};

window.my = {...window.my_default};
window.colw = '420px';	// column width

function App() {
  const refresh = MkRefresh();

  return (
  <Theme>
    <Box className="App" >
<BrowserRouter>
    	{window.my.id ? 
		<Home refresh={refresh} /> : 
		<Splash refresh={refresh} />}
</BrowserRouter>
    </Box>
  </Theme>
  );
}

export default App;
