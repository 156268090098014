import React, { useState, Fragment } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, Avatar
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import './Splash.css';
import { EnterIcon, ClipboardIcon, ImageIcon, SunIcon,
	EyeNoneIcon, EyeOpenIcon,
	} from '@radix-ui/react-icons'

import Granim from 'react-granim';

import {server_post, surl, Logo} from './Lib';
import {Login, Signup, Confirm} from './Login';
import {Expt} from './Expt';


function SplashNav() {
  return (
  <Flex m="2" justify="between" align="center" >
  	<Logo />
  	<Flex gap="2">
		<Link to="/login">
			<Button>
				Login
			</Button>
		</Link>
		<Link to="/signup">
			<Button variant="soft" >
				Sign Up
			</Button>
		</Link>
	</Flex>
  </Flex>
  );
}


function SplashFooter() {
	return (
	<Flex align="center" justify="between" style={{ background: '#ddd' }} >

	  <Text>
	  </Text>

	  <Flex align="center" gap="2" >

	  <LinkRadix href={surl('/static/about/terms.html')} 
	  	target="_blank" underline="hover" size="1" >
	     Terms
	  </LinkRadix>

	  <LinkRadix href={surl('/static/about/privacy.html')} 
	  	target="_blank" underline="hover" size="1" >
	     Privacy
	  </LinkRadix>

	  <LinkRadix href={surl('/static/about/about.html')} 
	  	target="_blank" underline="hover" size="1" >
	     About
	  </LinkRadix>

	<Text size='1' >
		&copy; 2024
	</Text>
	  </Flex>

	  <Link to="/expt" className="link-plain">
	    <Text>
	     &nbsp;&nbsp;&nbsp;&nbsp;
	    </Text>
	  </Link>

	</Flex>
	);
}

function SplashAnim() {
	return (
		<Box flexGrow="1" style={{ 
				position: 'relative', 
			}} >

			<Box className="splash-text" >
				<img 
			src="/static/img/noun-ai-community-6912138.svg" />
				
				<Text className="caption">
				<br/>
			Hato at your service
				<br/>
				</Text>


			<Box className="defn">
			<Text size="2" className="defn">
			<b>Hato</b> (ha·to) <i>noun</i>
			<ol>
			<li>
	(Short for <i>Hatamoto</i>) A samurai known for unwavering loyalty and readiness to act in service
			</li>
			<li>
	An intelligent aide, trusted confidant, always at your command, designed to serve, assist
			</li>
			</ol>

			</Text>

			</Box>
			</Box>


			<Granim  />
		</Box>
	);
}


function Splash({refresh}) {
  return (
<Flex direction="column" height="100vh" >
	<SplashNav />
	<Routes>
		<Route path="/" element={<SplashAnim />} />
		<Route path="/login" 
			element={<Login refresh={refresh} />} />
		<Route path="/signup" 
			element={<Signup refresh={refresh} />} />
		<Route path="/expt" 
			element={<Expt refresh={refresh} />} />
		<Route path="/forgotpw" 
			element={<Signup refresh={refresh} 
				forgotpw={true} />} />
		<Route path="/confirm/:pid" 
			element={<Confirm refresh={refresh} />} />
		<Route path="/resetpw/:pid" 
			element={<Confirm resetpw={true} 
				refresh={refresh} />} />
		<Route path="*" element={<SplashAnim />} />
	</Routes>
	<SplashFooter />
</Flex>
  );
}

export {Splash};
