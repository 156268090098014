import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate, 
		useParams, } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	TabNav, TextArea, Callout, Spinner,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import ReactMarkdown from 'react-markdown';

import { HatoIcon, QnAIcon, CircleIcon, JobIcon, DocIcon,
	NotebookIcon, PageIcon, FlagIcon,
	} from './Icons';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, ResetIcon, ShuffleIcon, 
	ChevronRightIcon, ChevronDownIcon,
	TriangleRightIcon, TriangleDownIcon,
	TrackNextIcon, TrackPreviousIcon,
	ExclamationTriangleIcon,
	} from '@radix-ui/react-icons'

import './AllNotebooks.css';
import {server_post, notify, Content, Logo, MkRefresh, Scrollable,
	MiniDateStr, DateStr, TabHeading, first_name, anon_show, randn,
	ShowJson, cap, AiFetchPoll, SpeechToText, 
	HighlightedText, json_mean, SearchBar, my_author_id, goto_nb,
	HasBusyPages,
	} from './Lib';



function AllNotebookHead({cid, nid}) {
	const root = window.my;
	const circle = root.circles[cid];
	const notebook = circle.notebooks[nid];
	const navigate = useNavigate();

	return (
	<>
<Box p="2" my="4" data-did={nid} 
	className="notebook-head"
	onClick={e => goto_nb(navigate, cid, nid)}
>
<Flex justify="between" align="center" >
	<NotebookIcon />
	<Flex flexGrow="1" px="2" gap="2" align="center">
		<Text>
			@{circle.handle}
		</Text>
		<Text size="1" color="gray" >
			<MiniDateStr date={notebook.dt_added} /> 
			{' '}
			#{notebook.name}
		</Text>
	</Flex>
	<Flex gap="2">
	{notebook.is_new ? <FlagIcon color="green" /> : null}
	<HasBusyPages notebook={notebook} />
	<ChevronRightIcon /> 
	</Flex>
</Flex>
</Box>
	</>
	);
}


function all_nids() {
	const nids = [];
	const nid2cid = {};

	Object.values(window.my.circles).forEach(circle => {
		Object.values(circle.notebooks).forEach(nb => {
			nids.push(nb.id);
			nid2cid[nb.id] = circle.id;
		});
	});
	return [nids, nid2cid];
}

// This shows notebooks across all circles
function AllNotebooks() {
	const [search, setSearch] = useState({text: '', fav: false});
	const [scrollRef, setScrollRef] = useState(null);
	const navigate = useNavigate();
	const root = window.my;

	const refresh = MkRefresh();
	const [anids, nid2cid] = all_nids();
	
	const narrow = nid => {
		const cid = nid2cid[nid];
		const notebook = root.circles[cid].notebooks[nid];

		if (search.fav && !notebook.is_favorite)
			return false;

		if (search.text) {
			const regexp = new RegExp(search.text, 'i');
			if (!regexp.test(notebook.name))
				return false;
		}

		return true;
	}

	let nids = anids
			.filter(nid => narrow(nid))
			.map(Number)
			.sort((a,b) => b - a);

	return (
	<>
<Content >
    <Flex align="center" justify="between" m="2" className="no-break" 
    	gap="3" 
    	>

      <TabHeading scrollRef={scrollRef}>
       My Notebooks
      </TabHeading>

      <SearchBar search={search} setSearch={setSearch} />

    </Flex>
</Content>
<Scrollable setScrollRef={setScrollRef} 
	>
<Content>
  <Card>
	{nids.map(nid => <AllNotebookHead 
		cid={nid2cid[nid]} nid={nid}
		key={nid} 
		/>)}
    
  </Card>
</Content>
</Scrollable>
	</>
	);
}



export {AllNotebooks};
