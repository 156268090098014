import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, Tooltip, ScrollArea,
	TabNav, TextArea, Callout, Spinner,
	} from '@radix-ui/themes';
import { DateTime } from 'luxon';
import { Link as LinkRadix } from '@radix-ui/themes';
import { Mic, MicOff } from 'lucide-react';
import { UAParser } from 'ua-parser-js';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, ResetIcon, ShuffleIcon,
	} from '@radix-ui/react-icons';

import { HatoIcon, QnAIcon,
	} from './Icons';


function surl(url) {
	// if local server, use 8000 port for django, 
	//	if not same port on production
	const isLocal = window.location.hostname === 'localhost' ||
			window.location.hostname === '127.0.0.1';
	
	return isLocal?	'http://localhost:8000' + url : url;
}

const getCookie = (name) => {
	let cookieValue = null;
	if (document.cookie && document.cookie !== '') {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			if (cookie.substring(0, name.length + 1) === name + '=') {
				cookieValue = decodeURIComponent(
					cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookieValue;
};

function csrfFromCookie() {
	return getCookie('csrftoken');
}

async function getCsrfToken() {
	return getCookie('csrftoken');
	
	let response, data;
	let csrfToken = getCookie('csrftoken');
	if (csrfToken)
		return csrfToken;

	// response = await fetch(surl('/login/')); 
	// data = await response.json();

	// if (window.my.current.csrfToken)
		// return window.my.current.csrfToken;

	// response = await fetch(surl('/api/get_csrf_token/')); 
	// data = await response.json();
	// window.my.current.csrfToken = data.csrfToken;
	// console.log('/api/get_csrf_token/', data.csrfToken);
	// return data.csrfToken;

	// csrfToken = getCookie('csrftoken');
	// if (!csrfToken)
		// csrfToken = data.csrfToken;
}

//-------------------------------

function first_name(name) {
	return name.split(' ')[0];
}

function anon_show(code) {
	const mid = parseInt(code.length / 2);
	return code.slice(0,mid) + ' ' + code.slice(mid);
}

function randn(lim) {
	lim = lim || 10000;
	return Math.floor( Math.random() * lim );
}

const cap = buf => {
	buf = buf.replaceAll('_', ' ');
	return buf.charAt(0).toUpperCase() + buf.slice(1);
}

//-------------------------------

function tojson(text) {
	try {
		return JSON.parse(text);
	}
	catch (e) {
		return null;
	}
}

async function server_post(url, formdata, setError) {
	let data = null;
	let error = '';
	let html = '';
	let rtext = '';

	formdata = formdata || {};

	const csrfToken = await getCsrfToken();
	let response = null;

	try {
		response = await fetch(surl(url), {	
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken' : csrfToken, 
			},
			body: JSON.stringify(formdata),
		});

		rtext = await response.text();
		data = tojson(rtext);

		if (response.statusText == 'Forbidden')
			error = 'Forbidden (CSRF not set)';
		else if (!response.ok) 
			error = data ? data.error : `${url} failed`; 
		else if (data.error)
			error = data.error;
	} 
	catch (err) {
		console.error('Error:', err);
		error = `An error occurred during ${url}: ${err}`;
	}
	console.log('server_post', url, formdata, data, error, response);
	if (error) {
		if (setError) {
			setError(error);
		}
		else {
			window.my.handlers.notify({message: error,
				color: 'crimson', modal:true,
				html: data ? '' : rtext});
		}
	}

	return data;
}

function DateStr({date}) {
	// const dt = new Date(date);

	const dt = DateTime.fromISO(date, {zone: 'local'});

	return (
	<span>
		{dt.toFormat('cccc, MMMM d, yyyy')}
	</span>
	);
}

function MiniDateStr({date}) {
	// const dt = new Date(date);

	const dt = DateTime.fromISO(date, {zone: 'local'});

	return (
	<span>
		{dt.toFormat('MMM d, HH:MM')}
	</span>
	);
}

function Error({error}) {
	if (!error)
		return null;

	return (
	<div className="error-message">
		{error} 
	</div>
	);
}

function $(sel) {
	const fc = sel.charAt(0);
	if (fc == '#' || fc == '.')
		return document.querySelector(sel);
	return document.getElementById(sel);
}


//--------- Circles utils ----------------

function my_author_id(authors, my_pid) {
	const ma = Object.values(authors).find(
			item => item.pid === my_pid);
	return ma.id;
}

function get_my_hids() {
	const hids = new Set();

	Object.values(window.my.circles).forEach(circle => {
		Object.values(circle.members).forEach(member => {
			hids.add( member.id );
		})
	});
	return [...hids];
}

function goto_nb(navigate, cid, nid) {
	navigate(`/circles/${cid}/notebook/${nid}`);
}

function HasBusyPages({notebook}) {
	let is_busy = false;

	Object.values(notebook.pages).forEach( page => {
		if (page.state == 'waiting')
			is_busy = true;
	});

	return is_busy ? <Spinner /> : null;
}


//--------- UI Components ----------------


function CountUp() {
	const [count, setCount] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCount(prevCount => prevCount + 1);
		}, 1000); // Update every 1000 milliseconds (1 second)

		return () => clearInterval(intervalId); // Cleanup on unmount
	}, []); // Empty dependency array ensures this runs once on mount

	return (
	<Text>
		{count}
	</Text>
	);
};

function AiFetchPoll({fetch2}) {
	/* attempt:
	 * -1 => waiting for user to click fetch
	 * 0 => issue first fetch request, by caller
	 * 1, 2, 3 ... => issue poll, by caller
	 * -1 => reset to -1 when done
	 */
	const [attempt, setAttempt] = useState(-1);
	const [error, setError] = useState('');

	const setError2 = arg => {
		setAttempt(-1);
		console.log('getMeaning.error', arg);
		setError(arg);
	}

	const reset = e => {
		setAttempt(-1);
		setError('');
	}

	const again = (done) => {
		// console.log('AiFetchPoll.again', {done, attempt});
		if (done) {
			setAttempt(-1);
		} else {
			setAttempt(prevAttempt => prevAttempt + 1);
		}
	}

	const fetch = useCallback(async () => {
		let cur = attempt;
		if (attempt < 0) {
			setAttempt(0);
			cur = 0;
		}
		fetch2(cur, setError2, again);
	}, [attempt]);

	useEffect(() => {
		// console.log('AiFetchPoll.useEfect', {attempt});
		let timer;
		if (attempt > 0 && attempt < 30) {
			timer = setTimeout(fetch, 1000);
		}
		return () => {
			clearTimeout(timer);
		}
	}, [attempt, fetch]);


	return (
    <Box>
      {attempt < 0 ? (
        <Button variant="soft" onClick={fetch}>
          Fetch with AI
        </Button>
      ) : (
        <Box>
          Fetching... <CountUp />

<Callout.Root my="2" >
  <Callout.Icon>
    <InfoCircledIcon />
  </Callout.Icon>
  <Callout.Text>
    AI can typically take 15 seconds
  </Callout.Text>
</Callout.Root>
	  
        </Box>
      )}
      {error && 
      <Box my="2" p="2" style={{ background: 'var(--yellow-2)' }}>
        <Flex justify="between" align="center">
	      <Heading size="3">
		Error
	      </Heading>
	      <Button variant="soft" onClick={reset} >
			<ResetIcon /> Reset
		     </Button>
         </Flex>
	 <hr/>

        <Box className="my-pre" my="4" >
		{error}
	</Box>
      </Box>
      }
    </Box>
	);
}

function Content({ children }) {
	return (
	<Flex direction="column" flexGrow="1" align="center" >
		<Box width={window.colw} >
			{children}
		</Box>
	</Flex>
	);
}


function Logo() {
	return (
	<Tooltip content="Home">
	  <Link to="/" className="link-plain">
	    <Text weight="bold" size="5" color="cyan" underline="hover" 
	    	style={{ fontFamily: 'Virgil' }}
	    >
		AI Hato
	    </Text>
	  </Link>
	</Tooltip>
	);
}

function MkRefresh() {
	const [count, setCount] = useState(0);
	return () => setCount( prevCount => prevCount + 1 );
}

function Scrollable({ children, setScrollRef }) {
	const scrollRef = useRef(null);

	useEffect( () => {
		if (setScrollRef) {
			setScrollRef(scrollRef);
			return () => setScrollRef(null);
		}
	}, []);

	return (
	<ScrollArea type="always" scrollbars="vertical" 
		style={{ height: '80%' }} ref={scrollRef} >
		{children}
	</ScrollArea>
	);
}

function TabHeading({children, scrollRef}) {
	return (
    <Heading size="4"
        style={{ cursor: 'pointer' }}
    	onClick={e => scrollRef && scrollRef.current?.scrollTo({ top: 0 })}
	>
      {children}
    </Heading>

	);
}

//--------------->>

const OpenAISpeechToText = ({ onTranscript }) => {

  const openaiApiKey = ''; // TBD

  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    // ... (your existing browser support check code)

    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
    };
  }, []);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        audioChunksRef.current = [];

        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.webm');
        formData.append('model', 'whisper-1');

        const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${openaiApiKey}`,
          },
          body: formData,
        });

        const data = await response.json();
        setTranscript(data.text);
        onTranscript(data.text);
      };

      mediaRecorderRef.current.start();
      setIsListening(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
      // Handle microphone access errors (e.g., show a notification to the user)
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsListening(false);
    }
  };

  const toggleListening = (e) => {
    e.preventDefault();

    if (isListening) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  // ... (your existing browser support check and conditional rendering)

  return (
    <Button variant="soft" onClick={toggleListening}>
      {isListening ? <MicOff /> : <Mic />}
    </Button>
  );
};


const GeminiSpeechToText = ({ onTranscript }) => {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const recognitionRef = useRef(null);

  const isBrowserSupported = () => {
    if (!window.my.settings.enableSpeech) return false;

    const parser = new UAParser();
    const result = parser.getResult();
    const browserName = result.browser.name;

    // Check for supported browsers (Chrome, Safari, Edge)
    if (
      !(
        result.browser.major &&
        (browserName === 'Chrome' || browserName === 'Safari' || browserName === 'Edge')
      )
    ) {
      console.log('SpeechToText: browser not supported', browserName);
      return false;
    }

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.log('SpeechToText: not supported');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!isBrowserSupported()) return;

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;

    recognitionRef.current.onresult = (event) => {
      let finalTranscript = '';
      let interimTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }

      const currentTranscript = finalTranscript + interimTranscript;
      setTranscript(currentTranscript);
      onTranscript(currentTranscript);
    };

    recognitionRef.current.onerror = (event) => {
      console.error('Speech recognition error', event);
      setIsListening(false);

      let errorMessage = 'Speech recognition error: ' + event.error;
      if (event.error === 'no-speech') {
        errorMessage = 'No speech was detected. Please try again.';
      } else if (event.error === 'audio-capture') {
        errorMessage = 'Audio capture failed. Please check your microphone.';
      } else if (event.error === 'not-allowed') {
        errorMessage = 'Speech recognition permission was denied. Please allow microphone access.';
      }

      window.my.handlers.notify({
        message: errorMessage,
        color: 'crimson',
        modal: false,
      });

      // Avoid automatically restarting on 'aborted' errors
      // This might lead to a loop if the underlying issue persists
    };

    recognitionRef.current.onend = () => {
      if (isListening) {
        // Only restart if the component is still actively listening
        recognitionRef.current.start();
      } else {
        setIsListening(false);
      }
    };

    return () => {
      if (recognitionRef.current) recognitionRef.current.abort();
    };
  }, [onTranscript]);

  const toggleListening = (e) => {
    e.preventDefault();

    if (recognitionRef.current) {
      if (isListening) {
        recognitionRef.current.stop();
      } else {
        recognitionRef.current.start();
      }
    }
    setIsListening(!isListening);
  };

  if (!isBrowserSupported()) return null;

  return (
    <Button variant="soft" onClick={toggleListening}>
      {isListening ? <MicOff /> : <Mic />}
    </Button>
  );
};


const SpeechToText = ({ onTranscript }) => {
	const [isListening, setIsListening] = useState(false);
	const [transcript, setTranscript] = useState('');
	const recognitionRef = useRef(null);

	const works = () => {
		return false; // disabled

		if (!window.my.settings.enableSpeech)
			return false;

		const parser = new UAParser();
		const result = parser.getResult();
		const bn = result.browser.name;

		console.log('SpeechToText', result);

		// Blink excludes Chromium but catches Chrome
		if (!(result.browser.major && (
		      bn === 'Chrome' || bn === 'Safari' || bn === 'Edge'
				))) {
			console.log('SpeechToText: browser wont work', bn);
			return false;
		}

		const SpeechRecognition = window.SpeechRecognition ||
				window.webkitSpeechRecognition;
		if (!SpeechRecognition) {
			console.log('SpeechToText: not supported');
			return false;
		}
		return true;
	}

	useEffect(() => {
		const SpeechRecognition = window.SpeechRecognition ||
				window.webkitSpeechRecognition;

		if (!SpeechRecognition)
			return;

		recognitionRef.current = new SpeechRecognition();
		recognitionRef.current.continuous = true;
		recognitionRef.current.interimResults = true;
		// recognitionRef.current.lang = 'en-US';

		recognitionRef.current.onresult = (event) => {
		  let finalTranscript = '';
		  let interimTranscript = '';

		  for (let i = event.resultIndex; i < event.results.length; ++i) {
		    if (event.results[i].isFinal) {
		      finalTranscript += event.results[i][0].transcript;
		    } else {
		      interimTranscript += event.results[i][0].transcript;
		    }
		  }

		  const currentTranscript = finalTranscript + interimTranscript;
		  setTranscript(currentTranscript);
		  onTranscript(currentTranscript);
		};

		recognitionRef.current.onerror = (ev) => {
			console.error('Speech recognition error', 
				ev);
			setIsListening(false);

			  let errorMessage = 'Speech recognition error ' 
			  	+ ev.error;
			  if (ev.error === 'no-speech') {
			    errorMessage = 'No speech was detected. Please try again.';
			  } else if (ev.error === 'audio-capture') {
			    errorMessage = 'Audio capture failed. Please check your microphone.';
			  } else if (ev.error === 'not-allowed') {
			    errorMessage = 'Speech recognition permission was denied. Please allow microphone access.';
			  }

			window.my.handlers.notify({
				message: errorMessage,
				color: 'crimson', modal:false,
				});

  if (ev.error === 'aborted' && isListening) {
    setTimeout(() => {
      recognitionRef.current.start();
    }, 500); // Wait for 1 second before restarting
  }
		};

		recognitionRef.current.onend = () => {
			  if (isListening) {
			    recognitionRef.current.start();
			  } else {
			    setIsListening(false);
			  }
		};

		return () => {
			if (recognitionRef.current) 
				recognitionRef.current.abort();
		};
	}, [onTranscript]);

	const toggleListening = e => {
		e.preventDefault();

		if (recognitionRef.current) {
			if (isListening) {
				recognitionRef.current.stop();
			} else {
				recognitionRef.current.start();
			}
		}
		setIsListening(!isListening);
	};

	if (!works())
		return null;

	return (
      <Button variant="soft" onClick={toggleListening} >
        {isListening ? <MicOff /> : <Mic />}
      </Button>
	  );
};

//--------------<<


function doshow(header, items) {
	header = header.toLowerCase();

	for (let i=0; i<items.length; i++) {
		const item = items[i];
		if (item && header.indexOf( item ) >= 0)
			return false;
	}
	return true;
}

function ShowJson({root, ignore}) {
	if (!root)
		return null;

	ignore = ignore || '';
	const items = ignore.split(',').map(x => x.toLowerCase());
	
	return (
<Box>
  {Object.keys(root).filter(key => doshow(key, items)).map( (key, i) => {
  	const val = root[key];

	if (Array.isArray(val)) {
		return (
		<Box my="2" key={i} >
		  <Text weight="bold">
		    {cap(key)}
		  </Text>
		  <Box ml="4">
		    <Text >
		      {val.join(', ')}
		    </Text>
		  </Box>
		</Box>
		);
	}
	else if (typeof val === 'object') {
		return (
		<Box my="2" key={i} >
		  <Text weight="bold">
		    {cap(key)}
		  </Text>
		  <Box ml="4">
		    <ShowJson root={val} ignore={ignore} />
		  </Box>
		</Box>
		);
	}
	else {
		return (
		<Box mb="2" key={i} >
		  <Text as="span" weight="bold">
		    {cap(key)} :
		  </Text>
		  <Text as="span" ml="2" >
		    {val}
		  </Text>
		</Box>
		);
	}
  
  })}
</Box>
	);
}

function notify(item) {
	return window.my.handlers.notify(item);
}

//-------------->> Dream Lib


function HighlightedText({ text, highlightFragment, highlightColor }) {
  if (!highlightFragment) return <Text>{text}</Text>;

  const regex = new RegExp(highlightFragment, 'gi');
  const highlightedText = text.replace(
    regex,
    `<span style="background-color: ${highlightColor};">$&</span>`
  );

  return <Text asChild>
    <div dangerouslySetInnerHTML={{ __html: highlightedText }} />
  </Text>;
}

// <ComponentInstanceIcon style={{ transform: 'scale(1.5)' }} />


function json_mean(dream) {
	let mean = {};
	if (!dream.meaning)
		return mean;
	try {
		mean =  JSON.parse(dream.meaning);
	}
	catch (e) {
		console.log('json.mean parse error', dream.meaning);
	}
	// console.log('meaning', dream.id, mean);
	return mean;
}

function SearchBar({search, setSearch}) {
	const [reset, setReset] = useState(false);

	const update = (field, val) => {
		setSearch( prev => ({ 
			...prev,
			[field] : val,
		}));
	}

	return (
<Flex align="center" gap="2" >
  <TextField.Root 
  	placeholder="Search..."
	variant="soft"
	value={search.text}
	onChange={e=>update('text', e.target.value)}
	>
    <TextField.Slot>
      <MagnifyingGlassIcon />
    </TextField.Slot>
  </TextField.Root>

  {search.text && 
  <IconButton onClick={e=>update('text', '')} variant="soft" >
  	<Cross1Icon />
  </IconButton>}

  <IconButton onClick={e=>update('fav', !search.fav)} variant="soft" >
    {search.fav ? 
           <Tooltip content="Show all">
		    <HeartFilledIcon /> 
	   </Tooltip>
	   :
           <Tooltip content="Show only favorites">
		    <HeartIcon />
	   </Tooltip>
    }
  </IconButton>

</Flex>
	);
}


//--------------<<

export {surl, getCsrfToken, getCookie, csrfFromCookie, server_post,
	DateStr, MiniDateStr, Error, $, first_name, anon_show, randn,
	Content, Logo, MkRefresh, notify, Scrollable, TabHeading,
	tojson, cap, ShowJson, AiFetchPoll, SpeechToText,
	HighlightedText, json_mean, SearchBar, my_author_id,
	goto_nb, HasBusyPages, get_my_hids,
	};
