import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, 
	useNavigate, useParams, } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, Callout,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import './Login.css';
import { EnterIcon, ClipboardIcon, ImageIcon, SunIcon,
	EyeNoneIcon, EyeOpenIcon, InfoCircledIcon
	} from '@radix-ui/react-icons'


import {server_post, Content, surl} from './Lib';

function Password({ password, setPassword }) {
	const [showPassword, setShowPassword] = useState(false);

	return (
<>
        <Text as="label" size="2" weight="medium" mb="2" htmlFor="password">
	  Password
	</Text>
	<TextField.Root
	  id="password"
	  type={showPassword ? 'text' : 'password'}
	  variant="classic"
	  placeholder="Password"
	  value={password}
	  onChange={e=>setPassword(e.target.value)}
	 >
	 <Flex align="center" pr="2" >
	   <IconButton size="1" variant="ghost" tabIndex="-1"
	     onClick={e=>{e.preventDefault(); setShowPassword(!showPassword)}}
	     >
	     {showPassword ? <EyeNoneIcon height="14" width="14" /> : 
	     		<EyeOpenIcon height="16" width="16" />}
	   </IconButton>
	 </Flex>
	</TextField.Root>

</>
	);
}


function Login({refresh}) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const emailRef = useRef(null);

	const login = async (e) => {
		e.preventDefault();

		let data = null, error = '';

		data = await server_post('/api/login/',
					{username:email, password},
					setError);
		if (data && !data.error) {
			window.my = {
				...window.my,
				...data.my,
			};
			navigate('/');
			refresh();
		}
	}

	useEffect( () => {
		if (emailRef.current)
			emailRef.current.focus();
	}, []);

	return (
<Content>
  <Card variant="classic" size="4">
    <form action="/">
      <Box height="40px" mb="4">
        <Heading as="h3" size="6" mt="-1">
	  Login
	</Heading>
      </Box>

      <Box mb="5">
        <Text as="label" size="2" weight="medium" mb="2" htmlFor="email">
	  Email
	</Text>
	<TextField.Root
	  id="email"
	  type="email"
	  variant="classic"
	  placeholder="user@domain.com"
	  ref={emailRef}
	  value={email}
	  onChange={e=>setEmail(e.target.value)}
	 />
      </Box>


      <Box mb="5" position="relative">
        <Box position="absolute" top="0" right="0" style={{ marginTop: -2 }}>
	   <Link to="/forgotpw" className="link-plain" tabIndex="-1" >
	     <Text size="2">
	       Forgot password?
	     </Text>
	   </Link>
	</Box>

	<Password password={password} setPassword={setPassword} />

      </Box>

      <Flex mt="6" justify="end" gap="3">
        <Button variant="solid" type="submit" onClick={login} >
	  Login
	</Button>
      </Flex>

      {error && 
      <Text color="red">
      	{error}
      </Text>}

    </form>
  </Card>
</Content>
	);
}

function Signup({refresh, forgotpw}) {
	const [name, setName] = useState(window.my.signup.name);
	const [email, setEmail] = useState(window.my.signup.email);
	const [code, setCode] = useState(window.my.signup.code);
	const [error, setError] = useState('');
	const [wait, setWait] = useState(false);
	const navigate = useNavigate();
	const firstRef = useRef(null);

	const signup = async (e) => {
		e.preventDefault();
		setWait(true);

		let data = null, error = '';

		data = await server_post('/api/signup/',
					{name, email, code, forgotpw},
					setError);
		window.my.signup = {name, email, code};

		if (data && !data.error) {
			window.my.code = data.code;
			if (forgotpw)
				navigate(`/resetpw/${data.pid}`);
			else
				navigate(`/confirm/${data.pid}`);
		}
		setWait(false);
	}

	useEffect( () => {
		if (firstRef.current)
			firstRef.current.focus();
	}, []);

	return (
<Content>
  <Card variant="classic" size="4">
    <form action="/">
      <Box height="40px" mb="4">
        <Heading as="h3" size="6" mt="-1">
	  {forgotpw ? 'Forgot Password' : 'Sign Up'}
	</Heading>
      </Box>

      {!forgotpw && <Box mb="5">
        <Text as="label" size="2" weight="medium" mb="2" htmlFor="name">
	  Name
	</Text>
	<TextField.Root
	  id="name"
	  type="text"
	  variant="classic"
	  placeholder="Your Name"
	  ref={firstRef}
	  value={name}
	  onChange={e=>setName(e.target.value)}
	 />
      </Box>}


      <Box mb="5">
        <Text as="label" size="2" weight="medium" mb="2" htmlFor="email">
	  Email
	</Text>
	<TextField.Root
	  id="email"
	  type="email"
	  variant="classic"
	  placeholder="user@domain.com"
	  value={email}
	  onChange={e=>setEmail(e.target.value)}
	 />
      </Box>

      <Box mb="5">
        <Text as="label" size="2" weight="medium" mb="2" htmlFor="code">
	  Invitation Code
	</Text>
	<TextField.Root
	  id="code"
	  type="text"
	  variant="classic"
	  placeholder="Code"
	  value={code}
	  onChange={e=>setCode(e.target.value)}
	 />
      </Box>

      <Box mb="5">
        <Text color="gray" size="2" weight="medium" mb="2" htmlFor="code">
	  By signing up, you agree to 
	  {' '}

	  <LinkRadix href={surl('/static/about/terms.html')} 
	  	target="_blank" underline="hover" size="1" >
	     Terms
	  </LinkRadix>

	{' and '}

	  <LinkRadix href={surl('/static/about/privacy.html')} 
	  	target="_blank" underline="hover" size="1" >
	     Privacy
	  </LinkRadix>
	  .

	</Text>
      </Box>


      <Flex mt="6" justify="end" gap="3">
        <Button variant="solid" type="submit" onClick={signup} >
	  {wait && <span className="button-spinner"></span>}
	  {forgotpw ? 'Send Reset Email' : 'Sign Up'}
	</Button>
      </Flex>

      {error && 
      <Text color="red">
      	{error}
      </Text>}

    </form>
  </Card>
</Content>
	);
}

function Confirm({refresh, resetpw}) {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [code, setCode] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const firstRef = useRef(null);
	const {pid} = useParams();

	const verify = async (e) => {
		e.preventDefault();

		let data = null, error = '';

		data = await server_post('/api/confirm/',
					{pid, code, password, resetpw},
					setError);
		if (data && !data.error) {
			window.my = {
				...window.my,
				...data.my,
			};
			navigate('/');
			refresh();
		}
	}

	useEffect( () => {
		if (firstRef.current)
			firstRef.current.focus();
	}, []);

	return (
<Content>
  <Card variant="classic" size="4">
    <form action="/">
      <Box height="40px" mb="4">
        <Heading as="h3" size="6" mt="-1">
	  {resetpw ? 'Reset Password' : 'Confirm'}
	</Heading>
      </Box>

       <Box mb="2">
	    <Callout.Root >
	      <Callout.Icon>
		    <InfoCircledIcon />
	      </Callout.Icon>
	      <Callout.Text>
	  Code sent in email from accounts@aihato.com.
	  <br/>
	  (Please also check your junk mail folders).
	      </Callout.Text>
	    </Callout.Root>
	</Box>

      <Box mb="5">
        <Text as="label" size="2" weight="medium" mb="2" htmlFor="code">
	  Code
	</Text>
	<TextField.Root
	  id="code"
	  type="text"
	  variant="classic"
	  placeholder="Code"
	  value={code}
	  ref={firstRef}
	  onChange={e=>setCode(e.target.value)}
	 />
      </Box>

      <Box mb="5" position="relative">

	<Password password={password} setPassword={setPassword} />

      </Box>

      <Flex mt="6" justify="end" gap="3">
        <Button variant="solid" type="submit" onClick={verify} >
	  {resetpw ? 'Reset' : 'Create Account'}
	</Button>
      </Flex>

      {error && 
      <Text color="red">
      	{error}
      </Text>}

    </form>
  </Card>
</Content>
	);
}



export {Login, Password, Signup, Confirm};
