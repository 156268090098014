import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	TabNav, TextArea, Callout, AlertDialog, DataList, Switch,
	Spinner, Select,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, UploadIcon,
	} from '@radix-ui/react-icons'

import './About.css';
import {server_post, notify, Content, Logo, MkRefresh, Scrollable,
	DateStr, TabHeading, first_name, anon_show, surl,
	} from './Lib';


//-------------

//----------

function AboutMenu({rmAccount}){

	return (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <Button variant="ghost" size="2">
	  <DotsVerticalIcon />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content size="2">
      <DropdownMenu.Item shortcut={<Cross1Icon />} onClick={rmAccount} >
      	Delete Account
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
	);
}


function About({refresh}) {
	const [search, setSearch] = useState({text: '', fav: false});
	const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
	const [scrollRef, setScrollRef] = useState(null);

	const navigate = useNavigate();
	const my = window.my;

	useEffect( () => {
		window.my.handlers.showBackMenu(); 
	}, []);

	const close = async(e) => {
		e.preventDefault();
		window.my.handlers.goBack(); 
	}
	
	return (
	<>
<Content>
    <Flex align="center" justify="between" m="2" >

    <TabHeading scrollRef={scrollRef}>
      About
    </TabHeading>

    </Flex>
</Content>

<Scrollable setScrollRef={setScrollRef} >
<Content>
  <Card className="about">

  <DataList.Root size="2" >

    <DataList.Item>
      <DataList.Label width="2rem" >
        About
      </DataList.Label >
      <DataList.Value >
        An early prototype of AI-OS
	<br/>
	by Raja Abburi
      </DataList.Value >
    </DataList.Item>

    <DataList.Item>
      <DataList.Label width="2rem" >
        Version
      </DataList.Label >
      <DataList.Value >
        0.001 Oct 27, 2024
	<br/>
	For a handful of invitees only
      </DataList.Value >
    </DataList.Item>

    <DataList.Item>
      <DataList.Label >
      	Contact
      </DataList.Label >
      <DataList.Value >
        <Box disply="inline">
      	raja@aihato.com
	<br/>
	+1 425 943 9414
	<br/>
	  <LinkRadix href={surl('/static/about/terms.html')} 
	  	target="_blank" underline="hover" size="1" >
	     Terms
	  </LinkRadix>

	{' '}

	  <LinkRadix href={surl('/static/about/privacy.html')} 
	  	target="_blank" underline="hover" size="1" >
	     Privacy
	  </LinkRadix>
	 </Box>
      </DataList.Value >
    </DataList.Item>

    {/*
    <DataList.Item>
      <DataList.Label >
      	Speech input
      </DataList.Label >
      <DataList.Value >
      	<Switch variant="soft" checked={enableSpeech} 
		onClick={e=>setEnableSpeech(val => !val)}
	/>
      </DataList.Value >
    </DataList.Item>


    <DataList.Item>
      <DataList.Label >
      	Horoscope
      </DataList.Label >
      <DataList.Value >
      	<Switch variant="soft" checked={doHoroscope} 
		onClick={e=>setDoHoroscope(val => !val)}
	/>
      </DataList.Value >
    </DataList.Item>
    */}

    <DataList.Item>
      <DataList.Label >
      </DataList.Label >
      <DataList.Value >
      <Flex gap="5">
      {/*
        <Button variant="soft" type="submit" onClick={update} >
	  {working ? 
	  	<Spinner /> :
		  <UploadIcon />}
	  Update
	</Button>
      */}
      </Flex>
      </DataList.Value >
    </DataList.Item>

  </DataList.Root >

    {/* Footer */}
  <br/>
    
    <Flex justify="between" align="center" mt="5" p="2"
    	className="profile-footer"
    >
      <Text size="1">
         Patent Pending
      </Text>
           <Tooltip content="Close">
        <Button variant="soft" onClick={close} >
	  <Cross1Icon /> 
	</Button>
	   </Tooltip>
    </Flex>
    
  </Card>
</Content>
</Scrollable>
	</>
	);
}


export {About};
