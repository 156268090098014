import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate, 
		useParams, } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	TabNav, TextArea, Callout, Spinner, RadioGroup, AlertDialog, 
	Separator, ScrollArea,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import ReactMarkdown from 'react-markdown';

import { HatoIcon, QnAIcon, CircleIcon, JobIcon, DocIcon,
	NotebookIcon, PageIcon,
	} from './Icons';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, ResetIcon, ShuffleIcon, 
	ChevronRightIcon, ChevronDownIcon,
	TriangleRightIcon, TriangleDownIcon,
	TrackNextIcon, TrackPreviousIcon,
	ExclamationTriangleIcon,
	EnterFullScreenIcon, ExitFullScreenIcon,
	} from '@radix-ui/react-icons'

import './Page.css';
import {server_post, notify, Content, Logo, MkRefresh, Scrollable,
	MiniDateStr, DateStr, TabHeading, first_name, anon_show, randn,
	ShowJson, cap, AiFetchPoll, SpeechToText, 
	HighlightedText, json_mean, SearchBar, my_author_id,
	} from './Lib';

//-----------

function PageWaiting({page}) {
	return (
<Box>
<Box className="page">
	<ReactMarkdown>
{'### AI processing ...\nThis typically takes 15 seconds'}
	</ReactMarkdown>
	<ShowCollect page={page} />
</Box>
	<Flex gap="2" justify="end" >
		<Spinner />
	</Flex>
</Box>
	);
}


function ShowError({page, name, item}) {
	return (
	<Box>
		Error. Unknown {item.kind} for {name}
	</Box>
	);
}


function ShowRadio({page, name, item}) {
	const [value, setValue] = useState(item.value);
	const inputRef = useRef(null);

	const update = val => {
		page.collect[name].value = val;
		setValue(val);
		console.log('Radio', val);
	}

	return (
  <RadioGroup.Root name={name} onValueChange={update} value={value} >
  {item.options.map( (option, i) => <RadioGroup.Item value={option}>
  		{option}
	</RadioGroup.Item>)}
  </RadioGroup.Root>
	);
}

function ShowTextArea({doFocus, page, name, item}) {
	const [value, setValue] = useState(item.value);
	const inputRef = useRef(null);

	useEffect( ()=> {
		if (inputRef.current && doFocus) {
			setTimeout( () => inputRef.current.focus(), 100 );
		}
	}, []);

// style={{ height: ht }}

	const update = val => {
		page.collect[name].value = val;
		setValue(val);
	}

	return (
	<Box mb="2" >
	<Text size="2">
		{item.label}
	</Text>
  <TextArea 
	color="blue" variant="soft" resize="vertical" size="2"
	ml="3"
	rows="5"
  	placeholder={item.placeholder}
	value={item.value}
	onChange={e=>update(e.target.value)}
	disabled={page.state == 'done'}
	ref={inputRef}
  />
  	</Box>
	);
}


function ShowCollect({page}) {

	// console.log('ShowCollect', page.number, page.collect, );

	const renderers = {textarea: ShowTextArea, radio: ShowRadio};
	let ifocus = -1;

	return (
	<>
	{Object.entries(page.collect).map( ([name, item], i) => {
		const ShowInput = renderers[item.kind] || ShowError;
		let doFocus = false;

		if (ifocus < 0 && item.kind == 'textarea') {
			ifocus = i;
			doFocus = true;
		}

		return <ShowInput 
			key={i}
			doFocus={doFocus}
			page={page}
			name={name} 
			item={item} 
			/>;
	})}
	</>
	);
}

function PageBody({page}) {
	return (
	<>
	<ReactMarkdown>
		{page.wbuf} 
	</ReactMarkdown>
	<ShowCollect page={page} />
	</>
	);
}

function PageFooter({notebook, page, choose, nextPage,}) {

	return (
	<Flex gap="2" justify="end" >
	{page.choices.map( (choice,i) => 
		<Button key={i} onClick={e=>choose(choice)}
			disabled={page.state == 'done'}
		>
			{choice}
		</Button>
	)}
	{page.to == 'ai' && notebook.is_new && 
		<Button onClick={nextPage}>
			Next
		</Button>
	}
	</Flex>
	);
}

function ShowPage({cid, nid, pgid, refresh, nextPage,
			isFullScreen, setIsFullScreen, }) {
	const root = window.my;
	const circle = root.circles[cid];
	const notebook = circle.notebooks[nid];
	const page = notebook.pages[pgid];
	console.log('ShowPage', {notebook, page}, );
	const [working, setWorking] = useState(false);


	if (!page)
		return null;

	const choose = async (choice) => {

		setWorking(true);
		const data = await server_post(
				'/api/page/done/',
				{
				pgid,
				aid : my_author_id(
					notebook.authors, circle.my_pid,
					),
				choice,
				collect: page.collect,
				}
		);
						
		setWorking(false);

		if (data) {
			notebook.pages = { ...notebook.pages, 
				...data.pages};
			notebook.authors = {...notebook.authors, 
				...data.authors};
			nextPage();
		}
	}

	return (
	<>
	{isFullScreen ? 

<AlertDialog.Root open={isFullScreen} onOpenChange={setIsFullScreen} 
>
  <AlertDialog.Content minWidth="95vw" height="88vh" 
  	className="full-wrap"
  >
  	<Box 
		className="full-body"
	>
		<Box px="6" >
			<PageBody page={page} />
		</Box>
	</Box>
  	<Box 
		className="full-footer"
	>

    <Flex gap="3" justify="between" style={{ background: '#fff' }} px="2" >

      <Flex gap="3" align="center" >

	<HatoIcon />

	<Text weight="bold" >
	   {circle.name}
	</Text>

	<Separator orientation="vertical" />
	
	<NotebookIcon />

	<Text color="gray" >
	   {notebook.name}
	</Text>

	<Separator orientation="vertical" />

	<Text color="gray" >
	   Page {page.number + 1}
	</Text>


      </Flex>

	<Flex gap="4" >
      <AlertDialog.Cancel>
        <Button variant="soft" color="gray">
          <Cross1Icon /> 
        </Button>
      </AlertDialog.Cancel>

	<PageFooter notebook={notebook} page={page} 
			choose={choose} nextPage={nextPage}  />
	</Flex>
    </Flex>

	</Box>
  </AlertDialog.Content>
</AlertDialog.Root>
	: 
<Box>
<Box className="page">
	<PageBody page={page} />
</Box>
	<PageFooter notebook={notebook} page={page} 
			choose={choose} nextPage={nextPage}  />
</Box>}
	</>

	);
}

export {ShowPage};
