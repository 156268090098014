import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	TabNav, TextArea, Callout, AlertDialog, DataList, Switch,
	Spinner, Select,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, UploadIcon, TrashIcon,
	} from '@radix-ui/react-icons'

import './MakeHato.css';
import {server_post, notify, Content, Logo, MkRefresh, Scrollable,
	DateStr, TabHeading, first_name, anon_show,
	} from './Lib';


//-------------

//----------

function MakeHatoMenu({rmAccount}){

	return (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <Button variant="ghost" size="2">
	  <DotsVerticalIcon />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content size="2">
      <DropdownMenu.Item shortcut={<Cross1Icon />} onClick={rmAccount} >
      	Delete Account
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
	);
}


function MakeHato({refresh}) {
	const [search, setSearch] = useState({text: '', fav: false});
	const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
	const [scrollRef, setScrollRef] = useState(null);
	const [working, setWorking] = useState(false);
	const [info, setInfo] = useState(false);

	const [enableSpeech, setEnableSpeech] = 
			useState(window.my.settings.enableSpeech);
	const [error, setError] = useState('');
	const [name, setName] = useState('');
	const [handle, setHandle] = useState('');
	const [desc, setDesc] = useState('');
	const [inputVar, setInputVar] = useState('');
	const [inputQ, setInputQ] = useState('');
	const [outputLabel, setOutputLabel] = useState('');
	const [AIPrompt, setAIPrompt] = useState('');

	const navigate = useNavigate();
	const my = window.my;

	const rmAccount2 = () => {
		setIsAlertDialogOpen(true);
	}

	const rmAccount = async (e) => {
		const data = await server_post('/api/delete/');

		if (data && !data.error) {
			window.my = {...window.my_default};
			navigate('/');
			refresh();
		}
	}

	const toggleInfo = async (e) => {
		setInfo(!info);
	}

	const close = async(e) => {
		e.preventDefault();
		window.my.handlers.goBack(); 
	}

	const update = async (e) => {
		e.preventDefault();

		setWorking(true);
		const data = await server_post('/api/hato/create/',
				{ name, handle, desc, inputVar, inputQ,
					outputLabel, AIPrompt,
				});

		if (data && !data.error) {
			my.store = {...my.store, ...data.store};
			// refresh();
			notify({message: 'Created'});
			navigate('/store');
			// window.my.handlers.goBack(); 
		}
		else {
			setError( (data & data.error) || 'Error' );
		}
		setWorking(false);
	}

	useEffect( () => {
		window.my.handlers.showBackMenu(); 
	}, []);
	
	return (
	<>
<Content>
    <Flex align="center" justify="between" m="2" >

    <TabHeading scrollRef={scrollRef}>
      Make Hato
    </TabHeading>

<Flex>
        <IconButton variant="soft" onClick={toggleInfo} mx="2" >
	  <InfoCircledIcon />
	</IconButton>

<Link to={`/store`} >
        <IconButton variant="soft" mx="2" >
	  <Cross1Icon />
	</IconButton>
</Link>
</Flex>

    </Flex>

{info && <Box my="2" >
<Card style={{ background: 'var(--lime-3)' }} >
	<Heading size="1" my="1" >
		About
	</Heading>

	<Text>
		The pattern this hato supports is:
	<ol>
		<li> Collect user input </li>
		<li> Send it to AI with instructions </li>
		<li> Show AI response to user </li>
	</ol>
		Stay tuned for other patterns
	</Text>
</Card>
</Box>}
</Content>

<Scrollable setScrollRef={setScrollRef} >
<Content>
  <Card className="profile">

	<Flex direction="column" gap="3">
		<label>
			<Text as="div" size="2" mb="1" weight="bold">
				Name
			</Text>
			<TextField.Root
				placeholder="Hato Name"
				value={name}
				onChange={e=>setName(e.target.value)}
			/>
		</label>
		<label>
			<Text as="div" size="2" mb="1" weight="bold">
				Handle
			</Text>
			<Flex>
			@<TextField.Root
				placeholder="Handle"
				value={handle}
				onChange={e=>setHandle(e.target.value)}
			/>
			</Flex>
		</label>
		<label>
			<Text as="div" size="2" mb="1" weight="bold">
				Description
			</Text>
			<TextField.Root
				placeholder="Description"
				value={desc}
				onChange={e=>setDesc(e.target.value)}
			/>
		</label>
		<label>
			<Text as="div" size="2" mb="1" weight="bold">
				Input name
			</Text>
			<TextField.Root
				placeholder="eg. Dream"
				value={inputVar}
				onChange={e=>setInputVar(e.target.value)}
			/>
		</label>
		<label>
			<Text as="div" size="2" mb="1" weight="bold">
				Input question
			</Text>
			<TextField.Root
				placeholder="eg. Please type your dream"
				value={inputQ}
				onChange={e=>setInputQ(e.target.value)}
			/>
		</label>
		<label>
			<Text as="div" size="2" mb="1" weight="bold">
				AI Prompt
			</Text>
  <TextArea 
	color="blue" variant="soft" resize="vertical" size="3"
  	placeholder="Please interpret this dream comprehensively from a Hall/Van de Castle system. Use alternate popular dream analysis methodologies also to deepen your interpretation. (Do not consider any information from previous requests or conversations. Assume each dreamer is a separate individual, distinct from prior requests). "
	  value={AIPrompt}
	  onChange={e=>setAIPrompt(e.target.value)}
  />
		</label>

		<label>
			<Text as="div" size="2" mb="1" weight="bold">
				Output Label
			</Text>
			<TextField.Root
				placeholder="eg. Dream Meaning"
				value={outputLabel}
				onChange={e=>setOutputLabel(e.target.value)}
			/>
		</label>

      <Flex mt="6" justify="end" gap="3">
        <Button variant="solid" type="submit" onClick={update} >
	  Create
	</Button>
      </Flex>

      {error && 
      <Text color="red">
      	{error}
      </Text>}

	</Flex>

    {/* Footer */}
  <br/>

<AlertDialog.Root open={isAlertDialogOpen} onOpenChange={setIsAlertDialogOpen} >
  <AlertDialog.Content maxWidth={window.colw} >
    <AlertDialog.Title>Delete Account</AlertDialog.Title>
    <AlertDialog.Description size="2">
      Are you sure? This will delete your account and all related data.
      This is not recoverable.
    </AlertDialog.Description>

    <Flex gap="3" mt="4" justify="end">
      <AlertDialog.Cancel>
        <Button variant="soft" color="gray">
          Cancel
        </Button>
      </AlertDialog.Cancel>
      <AlertDialog.Action>
        <Button variant="solid" color="red" onClick={rmAccount}>
          Delete Account
        </Button>
      </AlertDialog.Action>
    </Flex>
  </AlertDialog.Content>
</AlertDialog.Root>

    
    <Flex justify="between" mt="5" p="2"
    	className="profile-footer"
    >
      <Flex align="center">
      	
      </Flex>
      <Flex align="center" gap="2" >
      <MakeHatoMenu rmAccount={rmAccount2} />
      </Flex>
    </Flex>
    
  </Card>
</Content>
</Scrollable>
	</>
	);
}


export {MakeHato};
