import React, { useState, useEffect, useCallback, useRef } from 'react';
import { BrowserRouter, Routes, Route, Link, 
	useNavigate, useParams, } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	ScrollArea, Callout, Dialog, 
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon, ColorWheelIcon,
	DashboardIcon, InfoCircledIcon, Cross2Icon, GearIcon, IdCardIcon,
	ArrowLeftIcon, TransformIcon, FileTextIcon, EnvelopeClosedIcon,
	} from '@radix-ui/react-icons'

import { HatoIcon, QnAIcon, CircleIcon, JobIcon, DocIcon,
	NotebookIcon, PageIcon, StoreIcon,
	} from './Icons';

import './Home.css';
import {server_post, Content, Logo, Scrollable,
	TabHeading, MkRefresh, SearchBar, surl,
	} from './Lib';
import {Login} from './Login';
import {Hato} from './Hato';
import {Circles, ShowCircle} from './Circles';
import {AllNotebooks} from './AllNotebooks';
import {Store} from './Store';
import {Profile} from './Profile';
import {About} from './About';
import {MakeHato} from './MakeHato';

function HomeNav() {
  const [mode,setMode] = useState('circles');
  const [other,setOther] = useState(false);
  const navigate = useNavigate();

  const sections = [
  	{section: '', Icon: ArrowLeftIcon, tip: 'Back' },
  	{section: 'circles', Icon: CircleIcon, tip: 'My Hato' },
  	{section: 'notebooks', Icon: NotebookIcon, tip: 'Notebooks' },
  	{section: 'store', Icon: StoreIcon, tip: 'Hato Store' },
  	//{section: 'asks', Icon: QnAIcon, tip: "Q & A" },
  	//{section: 'docs', Icon: DocIcon, tip: 'Documents' },
  ];

  const nav = dest => {
  	let url = '';

	if (!dest)
		dest = mode;
		
	url = `/${dest}`;
	setMode(dest);
	setOther(false);
	navigate(url);
  }

  const onav = link => {
  	setOther(true);
	navigate(link);
  }

	useEffect( () => {
		window.my.handlers.showBackMenu = () => {
			setOther(true);
		};
		window.my.handlers.goBack = () => {
			nav('');
		};
		return () => {
		//	window.my.handlers.notify = null;
		};
	}, []);
	


  return (
  <Flex m="2" justify="between" align="center" >
  	<Logo />

  	<Flex gap="4">
	  {sections.filter(({section}) => (section || other))
	  		.map( ({section, Icon, tip}) => (
	    <Tooltip key={section} content={tip} >
		<IconButton size="3"
			variant={(mode === section && !other) ? 
				'solid' : 'outline'} 
			onClick={e=>nav(section)}
			>
		  <Icon />
		</IconButton>
	    </Tooltip>
	  ))}
	</Flex>

  	<Flex gap="2">

  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <Button variant="soft" size="2">
	  <HamburgerMenuIcon />
        <DropdownMenu.TriggerIcon />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content size="2">
      <DropdownMenu.Item shortcut={<IdCardIcon />} 
      	onClick={e=>onav('/profile')}
      >
		Profile
      </DropdownMenu.Item>
      <DropdownMenu.Item shortcut={<InfoCircledIcon />} 
      	onClick={e=>onav('/about')}
      >
		About
      </DropdownMenu.Item>

      <DropdownMenu.Separator />
      <DropdownMenu.Item color="red" shortcut={<ExitIcon />} 
      	onClick={e=>navigate('/logout')}
      >
		Logout
      </DropdownMenu.Item>

      <DropdownMenu.Separator />
      <DropdownMenu.Item color="blue" shortcut={<PersonIcon />}>
        {window.my.name}
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
	</Flex>
  </Flex>
  );
}


function HomeFooter() {
	const [notes, setNotes] = useState([
		// {message: 'Welcome', color:'red', modal: true, html: ''},
				]);
	const timeout = 1500;

	const close = useCallback(() => {
		setNotes(prevNotes => prevNotes.slice(1));
	}, []);

	useEffect( () => {
		window.my.handlers.notify = item => {
			setNotes(prevNotes => [...prevNotes, item]);
		};
		return () => {
		//	window.my.handlers.notify = null;
		};
	}, [close, timeout]);
	

	if (!notes.length)
		return null;

	const note = notes[0];

	const showMessage = () => (
	    <Callout.Root >
	      <Callout.Icon>
	  <IconButton variant="soft" onClick={close}>
	    <Cross2Icon />
	  </IconButton>
	      </Callout.Icon>
	      <Callout.Text>
		{note.message}
	      </Callout.Text>
	    </Callout.Root>
	);

	if (!note.modal)
		setTimeout(close, timeout);

	console.log('Notify', note);

	return (
	<Flex justify="center" wrap="nowrap">
	  <Box width={window.colw}>
	  {note.html ? 
          <Dialog.Root>
	    <Dialog.Title>Page</Dialog.Title>
            <Dialog.Trigger>
	      {showMessage()}
            </Dialog.Trigger>
            <Dialog.Content>
	      <Dialog.Description>
	      </Dialog.Description>
	        <Flex justify="between" mb="4">
		  <Heading size="4">
	        Details
		  </Heading>
		<Dialog.Close>
		  <IconButton variant="ghost">
		    <Cross2Icon />
		  </IconButton>
		</Dialog.Close>
		</Flex>
              <iframe className="notify-frame" srcDoc={note.html} /> 
            </Dialog.Content>
          </Dialog.Root>
	  :
	  showMessage()}
	  </Box>
	</Flex>
		);
}

//-------------


function Welcome() {
	const navigate = useNavigate();

	useEffect( () => {
		navigate('/circles');
	});

	return null;

	// For now, redir to dreams

	return (
	<Content>
	<Text>
		Welcome {window.my.name}!
	</Text>
	</Content>
	);
}

function Logout({refresh}) {
	const navigate = useNavigate();

	useEffect( () => {
		window.my = {...window.my_default};
		refresh();
		navigate('/');
	});

	return null;
}

function ScrollTop({scrollRef}) {
	const navigate = useNavigate();
	const {section} = useParams();

	useEffect( ()=> {
		scrollRef && scrollRef.current?.scrollTo({ top: 0 });
		navigate(`/${section}`, {replace: true});
	}, []);

	return null;
}
//-------------
// Temp

function Asks() {
	const [search, setSearch] = useState({text: '', fav: false});
	const [scrollRef, setScrollRef] = useState(null);

	const refresh = MkRefresh();

	return (
	<>
<Content >
    <Flex align="center" justify="between" m="2" >

      <TabHeading scrollRef={scrollRef}>
       Asks
      </TabHeading>

      <SearchBar search={search} setSearch={setSearch} />

    </Flex>
</Content>
<Scrollable setScrollRef={setScrollRef} 
	>
<Content>
  <Card>

  Hello
    
  </Card>
</Content>
</Scrollable>
	</>
	);
}

function Docs() {
	const [search, setSearch] = useState({text: '', fav: false});
	const [scrollRef, setScrollRef] = useState(null);

	const refresh = MkRefresh();

	return (
	<>
<Content >
    <Flex align="center" justify="between" m="2" >

      <TabHeading scrollRef={scrollRef}>
       My Docs
      </TabHeading>

      <SearchBar search={search} setSearch={setSearch} />

    </Flex>
</Content>
<Scrollable setScrollRef={setScrollRef} 
	>
<Content>
  <Card>

  Hello
    
  </Card>
</Content>
</Scrollable>
	</>
	);
}


//-------------

// Event Source Listener for pulling server push events
function Puller2() {
  const [events, setEvents] = useState([]);
  const eventSourceRef = useRef(null);

  useEffect( () => {
  	eventSourceRef.current = new EventSource(surl('/api/pull/'),
					{ withCredentials: true });

	eventSourceRef.current.onmessage = (ev) => {
		const nev = JSON.parse(ev.data);
		// console.log('EventSource:', nev);
		setEvents( prevEvents => [...prevEvents, nev] );
		// window.my.handlers.notify({message: nev.i})
	};

	eventSourceRef.current.onerror = err => {
		console.error('EventSource failed', err);
		// TBD try to reconnect
	}

	return () => {
		if (eventSourceRef.current) {
			eventSourceRef.current.close();
		}
	}
  }, []);

  return null;
}

//--------------- >> Poll count

function waiting_page_data() {
	const wpgids = {};
	const mids = {}
	const nid2cid = {};

	Object.values(window.my.circles).forEach( circle => {
		Object.values(circle.notebooks).forEach( notebook => {
			Object.values(notebook.pages).forEach( page => {
				if (page.state == 'waiting')
					wpgids[page.id] = {
						pgid: page.id,
						nid: notebook.id,
						cid: circle.id,
						mid: circle.member_id,
					}
					mids[ page.id ] = circle.member_id;
					nid2cid[ notebook.id ] = 
						circle.id;
			})
		})
	});

	return [wpgids, mids, nid2cid];
}

async function fetch_pages(pgids, mids) {
	const data =  await server_post(
			'/api/page/poll/',
			{
			pgids, 
			mids,
			}
	);
	return data;
}

function pgupdate(data, nid2cid) {
	Object.values(data.pages).forEach( page => {
		const cid = nid2cid[ page.nid ];
		const notebook = window.my.circles[cid].notebooks[page.nid];
		notebook.pages[ page.id ] = {...page};
		notebook.is_new = true;
		console.log('poll.pgupdate: notebook', page.nid);
	});
}

async function Poller() {
	if (window.my.poll.stop)
		return;

	window.my.poll.count++;
	// console.log('Poll', window.my.poll.count);

	const [wpgids, mids, nid2cid] = waiting_page_data();
	const pgids = Object.keys(wpgids);

	if (pgids.length) {
		const data = await fetch_pages(pgids, mids);
		console.log('Poll, waiting on', pgids, data);
		if (data) {
			pgupdate(data, nid2cid);
			window.my.handlers.refreshListNotebooks();
		}
	}
}


// Event Source Listener for pulling server push events
function PollPuller() {
	useEffect(() => {
		if (window.my.poll.id === null) {
			window.my.poll.id = setInterval(Poller, 1000);
		}
		return () => {
			clearInterval(window.my.poll.id);
			window.my.poll.id = null;
			window.my.poll.count = 0;
		}
	});

	return null;
}

//--------------- << Poll count

function Home({refresh}) {
  const [scrollRef, setScrollRef] = useState(null);

  return (
<Flex direction="column" height="100vh" gap="0">
	<PollPuller />
	<HomeNav />
	<Routes>
		<Route path="/" element={<Welcome />} />
		<Route path="/circles" 
			element={<Circles />} />
		<Route path="/circles/:cid" 
			element={<ShowCircle />} />
		<Route path="/circles/:cid/notebook/:nid" 
			element={<ShowCircle />} />
		<Route path="/asks" 
			element={<Asks />} />
		<Route path="/notebooks" 
			element={<AllNotebooks />} />
		<Route path="/store" 
			element={<Store />} />
		<Route path="/store/hato/:curHid" 
			element={<Store />} />
		<Route path="/store/hato/make" 
			element={<MakeHato refresh={refresh} />} />
		<Route path="/docs" 
			element={<Docs />} />
		<Route path="/profile" 
			element={<Profile refresh={refresh} />} />
		<Route path="/about" 
			element={<About refresh={refresh} />} />
		<Route path="/logout" 
			element={<Logout refresh={refresh} />} />

		<Route path="/top/:section" 
			element={<ScrollTop scrollRef={scrollRef} /> } />
	</Routes>
	<HomeFooter />
</Flex>
  );
}

export {Home};
